import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';

import { IDEATranslationsService } from '@idea-ionic/common';

import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private router: Router,
    public menuCtrl: MenuController,
    public app: AppService,
    public t: IDEATranslationsService
  ) {}

  shouldHideSplitPane(): boolean {
    return !this.app.user;
  }
  isPageWithoutMenu(): boolean {
    return !this.router.url || ['echo', 'auth'].some(x => this.router.url.startsWith('/' + x));
  }

  goToPage(path: string | string[], queryParams?: any): void {
    if (!Array.isArray(path)) path = [path];
    this.router.navigate(path, { queryParams });
    this.menuCtrl.close();
  }
}
