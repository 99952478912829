<ion-app>
  <ion-split-pane contentId="main" when="md" [disabled]="shouldHideSplitPane() || isPageWithoutMenu()">
    <ion-menu contentId="main" type="overlay">
      <ion-header>
        <ion-toolbar>
          <ion-thumbnail *ngIf="!app.isInMobileMode()" slot="start" class="tappable" (click)="app.reloadApp()">
            <ion-img src="/assets/icons/icon-store.jpg"></ion-img>
          </ion-thumbnail>
          <ion-button *ngIf="app.isInMobileMode()" fill="clear" (click)="menuCtrl.close()">
            <ion-icon name="close"> </ion-icon>
          </ion-button>
          <ion-buttons *ngIf="app.isInMobileMode()" slot="end" class="ion-padding-right">
            <ion-button fill="clear" (click)="app.openUserPreferences()">
              <ion-icon slot="icon-only" name="settings"></ion-icon>
            </ion-button>
            <ion-button fill="clear" (click)="app.logout()">
              <ion-icon slot="icon-only" name="exit-outline"></ion-icon>
            </ion-button>
            <idea-user-avatar
              *ngIf="app.user"
              class="tappable"
              slot="end"
              [src]="app.getUserImageURL(app.user)"
              [name]="app.user.name || ''"
              (click)="goToPage(['profile'])"
            ></idea-user-avatar>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content color="tertiary">
        <ion-list class="ion-no-padding ion-no-margin" color="tertiary">
          <ion-item color="tertiary">
            <ion-title>{{ 'MENU.TITLE' | translate }}</ion-title>
          </ion-item>
          <ion-item button color="tertiary">
            <ion-label>{{ 'MENU.MY_DRIVE' | translate }}</ion-label>
          </ion-item>
          <ion-item button color="tertiary">
            <ion-label>{{ 'MENU.SHARED_DRIVE' | translate }}</ion-label>
          </ion-item>
          <ion-item button color="tertiary" (click)="goToPage('dashboard')">
            <ion-label>{{ 'MENU.FHE_DRIVE' | translate }}</ion-label>
          </ion-item>
          <ion-item button color="tertiary">
            <ion-label>{{ 'MENU.SETTINGS' | translate }}</ion-label>
          </ion-item>
          <ion-item button color="tertiary">
            <ion-label>{{ 'MENU.DELETED_FILES' | translate }}</ion-label>
          </ion-item>
          <ion-item button color="tertiary">
            <ion-label>{{ 'MENU.API_SDK' | translate }}</ion-label>
          </ion-item>
        </ion-list>
        <div class="storage" class="ion-padding-top ion-margin-top">
          <ion-title> {{ 'STORAGE.TITLE' | translate }} </ion-title>
          <ion-card color="secondary">
            <ion-item lines="none">
              <ion-progress-bar [value]="0.68"></ion-progress-bar>
            </ion-item>
            <ion-item lines="none">
              <ion-label>
                <i>{{ 'STORAGE.X_OF_Y_USED' | translate : { x: 6.8, y: 10, unit: 'GB' } }}</i>
              </ion-label>
            </ion-item>
          </ion-card>
        </div>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
