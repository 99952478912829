import { Resource, epochISOString } from 'idea-toolbox';

export class ProjectUser extends Resource {
  /**
   * The project id.
   */
  projectId: string;
  /**
   * The user id
   */
  userId: string;
  /**
   * The user's email
   */
  email: string;
  /**
   * The role the user has on the project
   */
  role: ProjectRoles;

  load(x: any): void {
    super.load(x);
    this.projectId = this.clean(x.projectId, String);
    this.userId = this.clean(x.userId, String);
    this.email = this.clean(x.email, String);
    this.role = this.clean(x.role, Number, ProjectRoles.VIEW);
  }

  safeLoad(newData: any, safeData: any): void {
    super.safeLoad(newData, safeData);
    this.projectId = safeData.projectId;
    this.userId = safeData.userId;
  }

  validate(): string[] {
    const e = super.validate();
    if (this.iE(this.projectId)) e.push('projectId');
    if (this.iE(this.userId)) e.push('userId');
    if (this.iE(this.email)) e.push('email');
    return e;
  }

  canSeeProject() {
    return this.role >= ProjectRoles.VIEW;
  }
  canAddContent() {
    return this.role >= ProjectRoles.ADD_CONTENT;
  }
  canManageProject() {
    return this.role >= ProjectRoles.MANAGE;
  }
}

export enum ProjectRoles {
  VIEW = 10,
  ADD_CONTENT = 20,
  MANAGE = 30
}

export interface ProjectInvitation {
  /**
   * The ID of the invitation.
   */
  invitationId: string;
  /**
   * The email of the invited user.
   */
  email: string;
  /**
   * The projects this user has been invited to.
   */
  projects: { [projectId: string]: ProjectRoles };
  /**
   * The timestamp (in seconds) when the invitation will expire.
   */
  expiresAt: number;
  /**
   * The ID of the user who sent the invitiation.
   */
  invitedBy: string;
  /**
   * The date the invitation was sent.
   */
  invitedAt: epochISOString;
}
