/**
 * Variables to configure an ITER IDEA's cloud app, together with its inner modules.
 */
export const environment = {
  idea: {
    project: 'fhe',
    ionicExtraModules: ['common', 'variables', 'auth'],
    app: {
      version: '0.0.4',
      bundle: 'com.iteridea.fhe',
      url: 'https://app.fhe-app.link',
      mediaUrl: 'https://media.fhe-app.link',
      appleStoreURL: '',
      googleStoreURL: ''
    },
    api: { url: 'api2.fhe-app.link', stage: 'dev' },
    auth: {
      title: 'FHE',
      hasIntroPage: false,
      registrationIsPossible: true,
      singleSimultaneousSession: false,
      forceLoginWithMFA: false,
      passwordPolicy: {
        minLength: 8,
        requireLowercase: true,
        requireDigits: true,
        requireSymbols: true,
        requireUppercase: true
      }
    },
    website: 'https://iter-idea.com'
  },
  aws: {
    cognito: { userPoolId: 'eu-central-1_PsAityqVY', userPoolClientId: '2lj7ni02bju2qlp8qgaj03cdhg' }
  }
};
